<template>
	<span>
		<div class="avatar" :style="avatarSize" v-if="isLoggedIn">
			<img class="border border-0 border-primary avatar--image" :src="userProfile.profile_image" v-if="userProfile.profile_image"/> 
			<span class="bg-dark text-white avatar--letter" v-else>
				{{ userInitials }}
			</span> 
		</div>
		<i class="bi bi-person" v-else></i>
	</span>
</template>
<script>
export default {
	name: 'Avatar',
	props: {
		width: {
			type: Number,
			default: 36
		},
		height: {
			type: Number,
			default: 36
		},
  },
	computed: {
		avatarSize(){
			return {width: `${this.width}px`, height: `${this.height}px`}
		},
		userProfile(){
			return this.$store.state.userProfile;
		},
		userInitials(){
			const profile = this.userProfile;
			if(profile.fname){
				return `${profile.fname?.slice(0,1)}${profile.lname?.slice(0,1)}`;
			}else{
				return "";
			}
		},
		isLoggedIn(){
			return this.$store.state.isLoggedIn;
		},
	},
	data(){
		return {
			avatar_width: this.width,
			avatar_height: this.height,

		}
	},
	mounted(){
		
	}
}
</script>
<style scoped>
.avatar{
	display: inline-block;
    vertical-align: middle;
    position: relative;
	background-color: #000;
    color: #fff;
    border-radius: 50%;
}
.avatar--letter{
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: .9rem;
}
</style>
