<template>
	<div class="app-header" id="app-header" >
		<div class="row d-md-none fixed-top bg-white border-bottom" style="z-index:999 !important">
			<div class="col-9 app-header-logo" @click="$router.push('/')">
				<img src="https://socaislands.com/wp-content/uploads/2019/11/socaislands-png-logo.png" class="img-fluid app-logo ms-3 my-1"/>
            </div>
			<div class="col-3" align="right">
				<div class="me-3 my-1" data-bs-toggle="offcanvas" data-bs-target="#menuPanel" aria-controls="menuPanel">
					<i class="bi bi-filter-right" style="font-size:2.7rem;"></i>
				</div>
            </div>
		</div>
		<nav class="navbar navbar-expand-md navbar-light fixed-top border-bottom border-light d-none d-md-block bg-white py-0 my-0">
			<div class="container">
				<a class="navbar-brand" @click="$router.push('/')">
					<img src="https://socaislands.com/wp-content/uploads/2019/11/socaislands-png-logo.png" class="img-fluid app-logo"/>
				</a>
				<div class="">
					<ul class="navbar-nav d-flex me-auto small">
						<li class="nav-item text-center me-4">
							<router-link to="/" title="Explore" class="app-header-menu-item text-reset text-decoration-none"  data-bs-toggle="tooltip" data-bs-placement="bottom">
								<i class="bi bi-compass"></i><div class="small">Explore</div>
							</router-link>
						</li>
						<li class="nav-item text-center me-4">
							<router-link to="/bookings" title="My Bookings" class="app-header-menu-item text-reset text-decoration-none" data-bs-toggle="tooltip" data-bs-placement="bottom">
								<i class="bi bi-calendar"></i><br/><div class="small">Bookings</div>
							</router-link>
						</li>
						<li class="nav-item text-center me-4">
							<router-link tag="li" to="/crewfinder" title="Crewfinder" class="app-header-menu-item text-reset text-decoration-none" data-bs-toggle="tooltip" data-bs-placement="bottom">
								<i class="bi bi-people"></i><br/><div class="small">Crewfinder</div>
							</router-link>
						</li>
						<li class="nav-item text-center me-4">
							<router-link to="/marketplace" title="Marketplace" class="app-header-menu-item text-reset text-decoration-none" data-bs-toggle="tooltip" data-bs-placement="bottom">
								<i class="bi bi-shop-window"></i><br/><div class="small">Marketplace</div>
							</router-link>
						</li>
						<li class="nav-item text-center">
							<router-link to="/profile" title="My Profile" class="app-header-menu-item text-reset text-decoration-none" data-bs-toggle="tooltip" data-bs-placement="bottom">
								<Avatar  :width="36" :height="36" /><br/><div class="small">Profile</div>
							</router-link>
						</li>
					</ul>
				</div>
			</div>
		</nav>
		<div class="mb-5">
			<p>&nbsp;</p>
		</div>
		<div class="row mt-3" align="center" v-if="user.uid && !user.emailVerified">
			<div class="col-12 alert alert-warning" @click="btnVerifyEmailClick">
                <button class="btn btn-link text-reset" style="text-decoration:none;">
					Please verify your email address using the link in the email we sent you when you created your account. 
					Click here to resend the verification email.
				</button>
				<div v-if="isBusy">
					<div v-for="index in 4" :key="index" class="spinner-grow spinner-grow-sm text-warning me-2" role="status">
						<span class="visually-hidden">Loading...</span>
					</div>
				</div>
            </div>
        </div>


		<div class="offcanvas offcanvas-end" tabindex="-1" id="menuPanel" aria-labelledby="offcanvasRightLabel">
			<div class="offcanvas-header">
				<h5 id="offcanvasRightLabel"> </h5>
				<button type="button" class="btn-close me-1 text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
			</div>
			<div class="offcanvas-body mobile-menu">
				<div class="row mb-3" data-bs-dismiss="offcanvas" @click="$router.push('/profile')" v-if="isLoggedIn">
					<div class="col-auto ms-3">
						<Avatar :width="48" :height="48" />
					</div>
					<div class="col">
						<span class="h3" style="text-transform: capitalize; margin-bottom:0;">
							{{ userProfile.fname }} {{ userProfile.lname }} <br/>
						</span>
						<span class="small text-muted">Manage your profile</span>
					</div>
				</div>

				<div class="row my-2" align="center" v-else>
					<div class="col-10 d-grid">
						<router-link to="/login">
							<button class="btn btn-outline-dark col-11" data-bs-dismiss="offcanvas">Login</button>
						</router-link>
					</div>
				</div>

				<div class="row my-2" align="center">
					&nbsp;
				</div>
				<router-link to="/bookings">
					<div class="row mb-3" data-bs-dismiss="offcanvas">
						<div class="col-2" align="right"><i class="bi bi-calendar"></i></div>
						<div class="col-10">
							My Bookings
							<div class="small text-muted menu-subtext">Manage your upcoming trips</div>
						</div>
					</div>
				</router-link>
				<router-link to="/">
					<div class="row mb-3" data-bs-dismiss="offcanvas">
						<div class="col-2 " align="right"><i class="bi bi-compass"></i></div>
						<div class="col-10">
							Explore 
							<div class="small text-muted menu-subtext">Discover your next travel experience.</div>
						</div>
					</div>
				</router-link>
				<router-link to="/crewfinder">
					<div class="row mb-3" data-bs-dismiss="offcanvas">
						<div class="col-2" align="right"><i class="bi bi-people"></i></div>
						<div class="col-10">
							Crewfinder
							<div class="small text-muted menu-subtext">Find your next travel buddy.</div>
						</div>
					</div>
				</router-link>
				<router-link to="/marketplace">
					<div class="row" data-bs-dismiss="offcanvas">
						<div class="col-2" align="right"><i class="bi bi-shop-window"></i></div>
						<div class="col-10">
							Marketplace
							<div class="small text-muted menu-subtext">Buy, sell or trade travel items.</div>
						</div>
					</div>
				</router-link>
				<div class="row my-2" align="center">
					<div class="ms-2 mb-3 col-10 border-bottom">&nbsp;</div>
				</div>
				<router-link to="/messages">
					<div class="row mb-4" data-bs-dismiss="offcanvas">
						<div class="col-2" align="right"><i class="bi bi-chat-right-text"></i></div>
						<div class="col-10">Messages <span class="ms-4 rounded-pill badge bg-light">0</span></div>
					</div>
				</router-link>
				<router-link to="/notifications">
					<div class="row mb-2" data-bs-dismiss="offcanvas">
						<div class="col-2" align="right"><i class="bi bi-bell"></i></div>
						<div class="col-10">Notifications <span class="ms-1 rounded-pill badge bg-light">0</span></div>
					</div>
				</router-link>
				<div class="row my-2" align="center">
					<div class="ms-2 mb-3 col-10 border-bottom">&nbsp;</div>
				</div>
				
				<div class="row mb-3" data-bs-dismiss="offcanvas" @click="btnLogoutClick">
					<div class="col-2" align="right"><i class="bi bi-box-arrow-right"></i></div>
					<div class="col-10">Logout</div>
				</div>
				
			</div>
		</div>
	</div>
</template>

<script>
import { useToast } from "vue-toastification";
import { getAuth, sendEmailVerification } from "firebase/auth";
import Avatar from './common/Avatar.vue';
import { nextTick } from '@vue/runtime-core';

const toast = useToast();

export default {
	name: 'AppHeader',
	components: {
		Avatar
	},
	computed:{
		pageTitle(){
			return this.$store.state.pageTitle;
		},
		showHeader(){ 
			let pageTitle = this.$store.state.pageTitle;
			let currentChatId = this.$store.state.currentChatId;
			return (currentChatId && pageTitle=="Chats") ? true : false;
		},
		user(){
			return this.$store.state.loggedInUser;
		},
		userProfile(){
			return this.$store.state.userProfile;
		},
		isLoggedIn(){
			return this.$store.state.isLoggedIn;
		},
		msgCount(){
			alert("msg ", this.$store.state.messages);
			const messages = this.$store.state.messages || [];
			const filter = (msg)=>{
				return !msg.data.read.includes(this.user.uid);
			}
			return messages.filter(filter);
		},
	},
	data(){
		return{
			isBusy: false,
		}
	},
	methods:{
		btnVerifyEmailClick(){
			this.isBusy = true;
			const auth = getAuth();
			sendEmailVerification(auth.currentUser).then(()=>{
				toast.success("Verification email sent.", {
					timeout: 2000
				});
				this.isBusy = false;
			});
		},
		btnLogoutClick(){
			this.$store.dispatch("logOut");
		},
	},
}
</script>
