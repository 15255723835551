<template>
    <div id="app">
		<AppHeader v-if="!['new-listing', 'login', 'sign-up'].includes(pageTitle)" />
		<router-view />
		<!-- <div class="row my-5 py-3 border-top">
			<div class="col-12">
				<span class="fw-bold1">&copy; Soca Islands LLC</span>
				<br/>
				<span class="small text-secondary">
					 &bull; Tel: +1 (868) 388-2937 <br/>&bull; Email: explore@socaislands.com <br/> &bull; Social Media: @socaislands
				</span>
			</div>
		</div> -->
	 </div>
</template>

<script>
import AppHeader from "@/components/Header"
export default {
	components: { 
		AppHeader,
	},
	computed: {
		pageTitle(){
			return this.$store.state.pageTitle;
		},
	},
	mounted(){
		
	}
}
</script>
<style>
.avatar{
	display: inline-block;
    vertical-align: middle;
    position: relative;
	background-color: #000;
    color: #fff;
    border-radius: 50%;
}
.avatar--letter{
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: .9rem;
}

.item-caption{
	font-size: .7rem;
	text-transform: uppercase;
}


/* body *{
	font-size: .8rem;
} */
button > small{
	font-size:8pt;
	margin:0;
	display:block;
}
.form-control:focus, .btn-check:focus+.btn-dark, .btn-dark:focus{
	box-shadow: none;
}

.form-control.passwordbox{
	border-right:0;
}

.btn.passwordbox{
	border-color: #ced4da;
	border-left: 0;
}

.bold{
	font-weight: 800;
}
.btn-tile{
	cursor: pointer;
}

.btn-tile-title{
	position:absolute;
	top:0;
	float: left;
	color: #fff;
	margin: 1rem .75rem 1rem;
	background-color: rgba(0, 0, 0, 0);
	text-shadow: 1px 0px 3px rgba(0, 0, 0, 1);
}

/* .btn-tile-title h6{
	font-size: 14pt !important;
} */

.booking-summary{
	position: fixed;
	width: 30%;
	height: 80vh;
	border: solid 0px #000;
	overflow-y: scroll;
	overflow-x: hidden;
}

.app-header{
	padding: 0 .5rem;
	border-bottom: 0px solid #f1f1f1;
	margin-bottom: 0rem;
}
.app-logo{
	width: 80px;
	height: auto;
}
.app-header-menu{
	padding: 1rem 1rem;
	text-align: end;
}

.app-header-menu-item i {
	font-size: 1.5rem;
}

.app-header-menu-item > span {
	line-height: 0;
	font-size: .75rem !important;
}

.mobile-menu *{
	color: #212529;
	text-decoration: none;
}

.mobile-menu i {
	font-size: 1.5rem;
}

.index-menu{
	cursor: pointer;
}
.index-menu .fas{
	display: none;
}
.index-menu:hover{
	background-color: #f9f9f9;
}
.index-menu:hover .fas{
	display: block;
}

.profile-img{
	width:300px;
	border-radius: 7%;
}

.msg-avatar{
	border-radius: 100%;
	position:absolute; 
	bottom:1rem; 
	width:70%;
}

.msg-avatar-container{
	position: relative;
}

.right-msg-bubble{
	border-radius: 15px;
	border-bottom-right-radius: 0;
}

.left-msg-bubble{
	border-radius: 15px;
	border-bottom-left-radius: 0;
}

.crew-excerpt{
	overflow: hidden;
	word-wrap: break-word;
	text-overflow: ellipsis;
	max-height: 4.5em;
	line-height: 1.25em;
	display: -webkit-box;
	-webkit-line-clamp: 3; /* number of lines to show */
	-webkit-box-orient: vertical;
}

.excerpt{
	overflow: hidden;
	word-wrap: break-word;
	text-overflow: ellipsis;
	max-height: 4.5em;
	line-height: 1.5em;
	display: -webkit-box;
	-webkit-line-clamp: 3; /* number of lines to show */
	-webkit-box-orient: vertical;
}

.msg-excerpt{
	overflow: hidden;
	word-wrap: break-word;
	text-overflow: ellipsis;
	max-height: 1.5em;
	line-height: 1.5em;
	display: -webkit-box;
	-webkit-line-clamp: 1; /* number of lines to show */
	-webkit-box-orient: vertical;
}

.msg-highlight{
	border: 1px solid #007bff;
}

.msg-title{
	overflow: hidden;
	word-wrap: break-word;
	text-overflow: ellipsis;
	max-height: 1.5em;
	line-height: 1.5em;
	margin-bottom:-.1rem;
	display: -webkit-box;
	-webkit-line-clamp: 1; /* number of lines to show */
	-webkit-box-orient: vertical;
}

.mkt-title{
	overflow: hidden;
	word-wrap: break-word;
	text-overflow: ellipsis;
	max-height: 1.5em;
	line-height: 1.5em;
	display: -webkit-box;
	-webkit-line-clamp: 1; /* number of lines to show */
	-webkit-box-orient: vertical;
}

.crew-title{
	overflow: hidden;
	word-wrap: break-word;
	text-overflow: ellipsis;
	max-height: 1.5em;
	line-height: 1.5em;
	display: -webkit-box;
	-webkit-line-clamp: 1; /* number of lines to show */
	-webkit-box-orient: vertical;
}

.crew-subtitle{
	overflow: hidden;
	word-wrap: break-word;
	text-overflow: ellipsis;
	max-height: 3em;
	line-height: 1.5em;
	display: -webkit-box;
	-webkit-line-clamp: 2; /* number of lines to show */
	-webkit-box-orient: vertical;
	margin-top:-.8rem;
}

.title-type{
	float: right;
	font-size: 12px;
}
.msg-date{
	float: right;
	font-size: 10px;
}
.mkt-img{
	border-radius: 5%;
}
.pointer{
	cursor: pointer;
}

.message-panel{
	background-color: #f1f1f1;
	display: inline-block;
	height: 87vh;
	overflow: hidden;
	overflow-y: scroll;
}

.user-panel{
	display: inline-block;
	height: 55vh;
	overflow: hidden;
	overflow-y: scroll;
	width: 110%;
}

.message-pane{
	padding:2rem;
	display: inline-block;
	height: 72vh;
	overflow: hidden;
	overflow-y: scroll;
}

.message-box{
	background-color: #fff;
	border-top: 1px solid #f1f1f1;
	position:absolute; 
	padding:0 1rem;
	bottom:0; left:0; right:0;
}
.marketplace-newitem-alert{
	position:fixed;
	top:0; left:0; right:0;
	z-index: 999;
}
.modal-header{
	border:0;
}

.scrolling-wrapper{
	overflow-x: scroll;
	overflow-y: hidden;
	white-space: nowrap;
	margin: 0;
	margin-right: -15px;
	margin-left: -15px;
	border: 0px solid #000;
}

.scrolling-wrapper-item{
/* 		margin-right: .75rem;
*/		display: inline-block;
	vertical-align: text-top;
	-ms-overflow-style: none;  
	scrollbar-width: none;
}

.horizontal-scroller {
	overflow-x: auto; 
	white-space: nowrap; 
	-ms-overflow-style: none;  
	scrollbar-width: none;
}

.horizontal-scroller::-webkit-scrollbar {
	display: none;
}

.hide-scrollbar::-webkit-scrollbar {
	display: none;
}

.hide-scrollbar {
	overflow-x: auto; 
	white-space: nowrap; 
	-ms-overflow-style: none;  
	scrollbar-width: none;
}

.horizontal-scroller-item {
	display: inline-block; 
	float: none;
}

.item-country{
	width: 75px;
	margin-right: .85rem;
}

.item-country:first-child{
	margin-left: .5rem;
}

.scrolling-wrapper::-webkit-scrollbar {
	display: none;
}

.scrolling-wrapper h6{
	font-size: 9.5pt;
	white-space: normal;
}

.no-border{
	border: 0;
}

.card-img-round {
	width: 70px;
	border-radius: 100%;
}

.card-img-top {
	width: 100%;
	border-top-left-radius: calc(.7rem - 1px);
	border-top-right-radius: calc(.7rem - 1px);
}

.card-img {
	border-radius: calc(.7rem - 1px);
}

.card-img-container{
	min-height: 150px;
	display: block;
}

.chat-card-img-container{
	width:100%;
	display: block;
	min-height:360px;
}
.cc-logos > img{
	width: auto;
	height: 15px;
	margin: 0 2px;
}

.fab-container {
	position: fixed;
	bottom: 1.5rem;
	right: 1.5rem;
	cursor: pointer;
	width: 4rem;
	height: 4rem;
	z-index: 999;
}

.fab-icon-button{
	width: 4rem;
	height: 4rem;
	border-radius: 100%;
	box-shadow: 3px 3px 5px #aaaaaa;
}

.fab-icon-button i{
	display:flex;
	align-items:center;
	justify-content:center;
	height: 100%;
	font-size: 3rem;
}

.btn:focus {
	outline: 0;
	box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%);
}

.mobile-menu-link-active{
	font-weight: bold;
}

.mobile-menu-link-active .menu-subtext{
	font-weight: 400;
}

@media only screen and (max-width: 800px) {
	.app-header{
		padding: .5rem 1rem;
		margin-bottom: .5rem;
		border-bottom: 0px solid #f1f1f1;
		text-align: left;
	}

	.app-header-logo{
		margin: auto;
	}
	.app-logo{
		width: 70px;
		height: auto;
	}

	.app-header-menu > .btn-lg {
		padding: .5rem .75rem;
	}
	.card{
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0);
	}
	.message-panel{
		background-color: #f1f1f1;
		display: inline-block;
		height: 85vh;
		overflow: hidden;
		overflow-y: scroll;
	}
	.user-panel{
		width:100%;
		padding-left:.5rem;
		height: 65vh;
		overflow: hidden;
		overflow-y: scroll;
	}
	.message-pane{
		padding: 0 1rem 5rem;
		height: 68vh;
	}
	.card-img-container{
		width:100%;
		display: block;
		min-height:200px;
	}
	.chat-card-img-container{
		width:100%;
		display: block;
		min-height:200px;
	}
}
</style>