/* import firebase from "firebase/app"
import "firebase/firestore"
import "firebase/auth"
import "firebase/storage"
import "firebase/analytics"
import "firebase/messaging"

// firebase init goes here
const config = {
	apiKey: "AIzaSyAqfTsxq3Ub6Y-glm0TOnU8pmfmmGRJE9s",
	authDomain: "app-soca-islands.firebaseapp.com",
	databaseURL: "https://app-soca-islands.firebaseio.com",
	projectId: "app-soca-islands",
	storageBucket: "app-soca-islands.appspot.com",
    messagingSenderId: "18088136934",
    appId: "1:18088136934:web:75851d00d16b6833042b27",
    measurementId: "G-6ZHCJ8FV41"
}

firebase.initializeApp(config)
firebase.analytics()

const db = firebase.firestore()
const storageRef = firebase.storage().ref()
const auth = firebase.auth()
var messaging = false
if(firebase.messaging.isSupported()){
    messaging = firebase.messaging ()
    messaging.getToken({ vapidKey: "BNvIEh59YfC-I3C4saFsuCT_uDW2UaL2bmkslWyLMO5bhy8FUjSkJfrcXTWQLzBt5fKEtZPD9_uqtrJCYDJdoIY" })
}


// firebase collections
const fcmtokensCollection = db.collection("si_fcmtokens")
const attendeesCollection = db.collection("si_attendees")
const eventsCollection = db.collection("si_events")
const ticketsCollection = db.collection("si_tickets")
const customerCollection = db.collection("islanders")
const ordersCollection = db.collection("si_orders")
const toursCollection = db.collection("tours")
const postsCollection = db.collection("posts")
const crewCollection = db.collection("si_crewfinder")
const likesCollection = db.collection("likes")
const marketCollection = db.collection("si_marketplace")
const messageCollection = db.collection("si_messages")
const reviewsCollection = db.collection("si_reviews")
const paymentsCollection = db.collection("si_payments")
const guestlistCollection = db.collection("si_guestlist")
const workTravelCollection = db.collection("si_workTravel")

export {
    firebase,
    db,
    auth,
    messaging,
    customerCollection,
    toursCollection,
    ordersCollection,
    postsCollection,
    crewCollection,
    likesCollection,
    marketCollection,
    storageRef,
    messageCollection,
    reviewsCollection,
    paymentsCollection,
    attendeesCollection,
    eventsCollection,
    ticketsCollection,
    guestlistCollection,
    workTravelCollection,
    fcmtokensCollection,
} */

import { initializeApp } from "firebase/app";

import { 
	getFirestore, 
} from "firebase/firestore";

import { 
	getAuth, 
	signOut,
	createUserWithEmailAndPassword, 
	signInWithEmailAndPassword, 
	onAuthStateChanged,
} from "firebase/auth";

const firebaseConfig = {
	apiKey: "AIzaSyAqfTsxq3Ub6Y-glm0TOnU8pmfmmGRJE9s",
	authDomain: "app-soca-islands.firebaseapp.com",
	databaseURL: "https://app-soca-islands.firebaseio.com",
	projectId: "app-soca-islands",
	storageBucket: "app-soca-islands.appspot.com",
    messagingSenderId: "18088136934",
    appId: "1:18088136934:web:75851d00d16b6833042b27",
    measurementId: "G-6ZHCJ8FV41"
}

const firebase = initializeApp(firebaseConfig);
const db = getFirestore();
const auth = getAuth();
const authStateChanged = onAuthStateChanged;

const emailTemplates = {
	NEW_BOOKING: "booking_received",
	NEW_PAYMENT: "new_payment",
	NEW_MANUAL_PAYMENT: "new_manual_payment",
	CONFIRM_BOOKING: "booking_confirmed",
	FAILED_BOOKING: "booking_failed",
	CONFIRM_EMAIL: "email_address_confirmed",
	NEW_ACCOUNT: "welcome_to_soca_islands",
}

//collections
const collections = {
	APP_BAND_ROLES: "band_roles",
	APP_PERMISSIONS: "app_permissions",
	BANDS: "bands",
	CONTACTS: "si_address_book",
	EMAIL_MESSAGES: "si_app_email_messages",
	EVENTS: "si_events",
	FCM_TOKENS: "si_fcmtokens",
	ORDERS: "si_bookings",
	FLIGHT_BOOKINGS: "si_flight_bookings",
	PAYMENTS: "si_transactions",
	EVENT_SETTINGS: "event_settings",
	ORGANISATIONS: "organisations",
	PAYMENT_OPTIONS: "payment_options",
	PAYOUT_METHODS: "payout_methods",
	PROFILES: "si_users",
	MESSAGES: "si_messages",
	MARKETPLACE: "si_marketplace",
	CREWFINDER: "si_crewfinder",
	REVIEWS: "si_reviews",
	REPORTS: "si_reports",
	RICONVENTION: "si_riconvention_2023",
}

//sub collections
const subCollections = {
	ADD_ONS: "addons",
	AVAILABILITY: "availability",
	TRANSPORT: "transport",
	HOTELS: "hotels",
	COSTUMES: "costumes",
	JOURNAL_ENTRIES: "journal_entries",
	TRAVELLERS: "travellers",
	ATTRS: "attributes",
	ATTR_VALUES: "attribute_values",
	BAND_LOGS: "logs",
	BAND_ROLES: "roles",
	BAND_TEAM: "team",
	EVENTS: "events",
	EVENT_ADDONS: "addons",
	EVENT_LOGS: "logs",
	EVENT_ORDERS: "orders",
	EVENT_PACKAGES: "packages",
	EVENT_PAYMENTS: "payments",
	EVENT_PROMO_CODES: "promo_codes",
	EVENT_SECTIONS: "sections",
	MESSAGES: "messages",
	PACKAGE_ATTRS: "attributes",
	PACKAGE_ATTR_VALUES: "attribute_values",
	PROFILES: "profiles",
	PROFILE_PRIVATE_DATA: "profile_private_data",
}

export {
	auth,
	authStateChanged,
	db,
	collections,
	createUserWithEmailAndPassword,
	emailTemplates,
	firebase,
	signInWithEmailAndPassword,
	subCollections,
	signOut,
}