import { createRouter, createWebHistory } from "vue-router";
import { getAuth } from "firebase/auth";

const routes = [
    {
        path: "/",
        name: "Experiences",
        component: () => import(/* webpackChunkName: "explore" */ "@/views/Experiences"),
        meta: {
            requiresAuth: false,
            title: "Explore Soca Islands",
        },
    },
    {
        path: "/book/flight/jfk-gnd",
        name: "BookFlight",
        component: () => import(/* webpackChunkName: "book" */ "@/views/BookFlight"),
        meta: {
            requiresAuth: false,
            title: "Book Your Flight - Soca Islands",
        },
    },
    {
        path: "/book/ri-convention",
        name: "RIConvention",
        component: () => import(/* webpackChunkName: "book" */ "@/views/RIConvention"),
        meta: {
            requiresAuth: false,
            title: "Rotary Windies - Soca Islands",
        },
    },
    {
        path: "/book/ri-convention/success",
        name: "RIConventionSuccess",
        component: () => import(/* webpackChunkName: "book" */ "@/views/RIConventionSuccess"),
        meta: {
            requiresAuth: false,
            title: "Rotary Windies - Soca Islands",
        },
    },
    {
        path: "/book/ri-convention/packages",
        name: "RIConventionPackages",
        component: () => import(/* webpackChunkName: "book" */ "@/views/RIConventionPackages"),
        meta: {
            requiresAuth: false,
            title: "Rotary Windies - Soca Islands",
        },
    },
    {
        path: "/travel-dates/:event_id/book/:travel_date_id",
        name: "BookNow",
        component: () => import(/* webpackChunkName: "book" */ "@/views/BookNow"),
        meta: {
            requiresAuth: true,
            title: "Book Now - Soca Islands",
        },
    },
    {
        path: "/travel-dates/:event_id",
        name: "TravelDates",
        component: () => import(/* webpackChunkName: "book" */ "@/views/TravelDates"),
        meta: {
            requiresAuth: false,
            title: "Travel Dates - Soca Islands",
        },
    },
    {
        path: "/bookings",
        name: "Bookings",
        component: () => import(/* webpackChunkName: "bookings" */ "@/views/Bookings"),
        meta: {
            requiresAuth: true,
            title: "My Bookings - Soca Islands",
        },
    },
    {
        path: "/back-office",
        name: "Backoffice",
        component: () => import(/* webpackChunkName: "bookings" */ "@/views/BackOffice"),
        meta: {
            requiresAuth: true,
            title: "My Bookings - Soca Islands",
        },
    },
    {
        path: "/profile",
        name: "Profile",
        component: () => import(/* webpackChunkName: "profile" */ "@/views/Profile"),
        meta: {
            requiresAuth: true,
            title: "My Profile - Soca Islands",
        },
    },
    {
        path: "/marketplace",
        name: "Marketplace",
        component: () => import(/* webpackChunkName: "marketplace" */ "@/views/Marketplace"),
        meta: {
            requiresAuth: false,
            title: "Marketplace - Soca Islands",
        },
    },
    {
        path: "/marketplace/add",
        name: "MarketplaceAdd",
        component: () => import(/* webpackChunkName: "marketplace" */ "@/views/MarketplaceAdd"),
        meta: {
            requiresAuth: true,
            title: "New Marketplace Items",
        },
    },
    {
        path: "/marketplace/list",
        name: "MarketplaceList",
        component: () => import(/* webpackChunkName: "marketplace" */ "@/views/MarketplaceList"),
        meta: {
            requiresAuth: true,
            title: "My Marketplace Listings",
        },
    },
    {
        path: "/crewfinder",
        name: "Crewfinder",
        component: () => import(/* webpackChunkName: "crewfinder" */ "@/views/Crewfinder"),
        meta: {
            requiresAuth: true,
            title: "Crewfinder - Soca Islands",
        },
    },
    {
        path: "/crewfinder/add",
        name: "CrewfinderAdd",
        component: () => import(/* webpackChunkName: "crewfinder" */ "@/views/CrewfinderAdd"),
        meta: {
            requiresAuth: true,
            title: "New Crewfinder Trip",
        },
    },
    {
        path: "/crewfinder/list",
        name: "CrewfinderList",
        component: () => import(/* webpackChunkName: "crewfinder" */ "@/views/CrewfinderList"),
        meta: {
            requiresAuth: true,
            title: "My Crewfinder Listings - Soca Islands",
        },
    },
    {
        path: "/login",
        name: "Login",
        component: () => import(/* webpackChunkName: "auth" */ "@/views/Login"),
        meta: {
            requiresAuth: false,
            title: "Login - Soca Islands",
        },
    },
    {
        path: "/forgot-password",
        name: "ForgotPassword",
        component: () => import(/* webpackChunkName: "auth" */ "@/views/ForgotPassword"),
        meta: {
            requiresAuth: false,
            title: "Forgot Password - Soca Islands",
        },
    },
    {
        path: "/reset-password",
        name: "ResetPassword",
        component: () => import(/* webpackChunkName: "auth" */ "@/views/ResetPassword"),
        meta: {
            requiresAuth: false,
            title: "Reset Your Password - Soca Islands",
        },
    },
    {
        path: "/auth",
        name: "Auth",
        component: () => import(/* webpackChunkName: "auth" */ "@/views/Auth"),
        meta: {
            requiresAuth: false,
            title: "Reset Your Password - Soca Islands",
        },
    },
    {
        path: "/signup",
        name: "Signup",
        component: () => import(/* webpackChunkName: "auth" */ "@/views/Signup"),
        meta: {
            requiresAuth: false,
            title: "Create a Soca Islands Account",
        },
    },
    {
        path: "/messages",
        name: "AppMessages",
        component: () => import(/* webpackChunkName: "messages" */ "@/views/AppMessages"),
        meta: {
            requiresAuth: true,
            title: "Messages - Soca Islands",
        },
    },
    {
        path: "/notifications",
        name: "Nofifications",
        component: () => import(/* webpackChunkName: "notifications" */ "@/views/AppMessages"),
        meta: {
            requiresAuth: true,
            title: "Notifications - Soca Islands",
        },
    },
    {
        path: "/destinations/:destination_id",
        name: "Destinations",
        component: () => import(/* webpackChunkName: "experience" */ "@/views/Destinations"),
        meta: {
            requiresAuth: false,
            title: "Destinations - Soca Islands",
        },
    },
    {
        path: "/:trip_category",
        name: "TripCategory",
        component: () => import(/* webpackChunkName: "experience" */ "@/views/TripCategory"),
        meta: {
            requiresAuth: false,
            title: "Experience - Soca Islands",
        },
    },
    {
        path: "/:trip_category/:trip_id",
        name: "Trip",
        component: () => import(/* webpackChunkName: "experience" */ "@/views/Trip"),
        meta: {
            requiresAuth: false,
            title: "Soca Islands",
        },
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0, behavior: "auto" };
        }
    },
    linkActiveClass: "mobile-menu-link-active",
});

router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
    const auth = getAuth();
    const currentUser = auth.currentUser;
    const windowTitle = to.meta?.title ? to.meta.title : "Soca Islands";

    window.document.title = windowTitle;

    if (requiresAuth && !currentUser) {
        next("/login?from=" + to.path);
    } else if (requiresAuth && currentUser) {
        next();
    } else {
        next();
    }
});

export default router;
