import { createApp } from "vue";
import moment from "moment";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import draggable from "vuedraggable"
import { Skeletor } from "vue-skeletor";
import "vue-skeletor/dist/vue-skeletor.css";
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import LogRocket from 'logrocket';
import VueGtag from "vue-gtag";

LogRocket.init('soca-islands/app-soca-islands');

const toastOptions = {
	position: POSITION.BOTTOM_LEFT,
	shareAppContext: true,
};

let app;
const auth = getAuth();
onAuthStateChanged(auth, () => {
	if (!app) {
		app = createApp(App);
		app.component(Skeletor.name, Skeletor);
		app.component(draggable.name, draggable);
		app.config.globalProperties.$moment = moment;
		app.use(store).use(router).use(VueGtag, {
 			pageTrackerTemplate(to) {
				return {
				  page_path: to.path
				}
			},
			config: { 
			  id: "G-6ZHCJ8FV41",
			},
		  }, router).use(Toast, toastOptions).mount("#app");
	}
})

